import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { CategoryModel } from '@nimles/models';
import {
  Column,
  Container,
  Row,
  Section,
  Share,
} from '@nimles/react-web-components';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React, { useContext } from 'react';
import { getImageUrl } from '../../../utils';

const OuterSection = styled(Section)`
  padding: 0;
  color: ${({ theme }) => theme.banner?.primary?.onColor};

  h1,
  h2 {
    margin-bottom: 0;
    text-align: center;
  }
`;

const InnerSection = styled(Section)`
  padding: 80px 0 0;
  min-height: 50vh;

  background: rgba(0, 0, 0, 0.4);
`;

interface Props {
  category: CategoryModel;
  location: Location;
}

export const CategoryBanner = ({ category, location: { href } }: Props) => {
  const { language } = useContext(I18nextContext);
  const theme = useTheme();

  const {
    summary,
    longName,
    longNameLocalized,
    name,
    nameLocalized,
    summaryLocalized,
    bannerId,
  } = category;

  return (
    <OuterSection backgroundImage={getImageUrl(bannerId, 1920)}>
      <InnerSection flex>
        <Container flex justify="center">
          <h1>
            {(language && longNameLocalized?.[language]) ||
              longName ||
              (language && nameLocalized?.[language]) ||
              name}
          </h1>
          <h2>{(language && summaryLocalized?.[language]) || summary}</h2>
        </Container>
        <Container>
          <Row wrap="wrap" justify="space-between" align="center">
            <Column></Column>
            <Column>
              <Share
                href={href}
                title={category.name}
                description={category.summary}
                color={theme.banner.primary.onColor}
              />
            </Column>
          </Row>
        </Container>
      </InnerSection>
    </OuterSection>
  );
};
