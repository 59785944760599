import React from 'react';
import Layout from '../layouts/Layout';
import { getImageUrl } from '../utils';
import { CategoryModel, OrganizationModel } from '@nimles/models';
import queryString from 'query-string';
import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { ApolloProvider, gql, useQuery } from '@apollo/react-hooks';
import { CategoryPage } from '../components/category/page/CategoryPage';
import { ProductGroupWithProductProperties } from '../types';
import { graphql } from 'gatsby';

export const client: any = new ApolloClient({
  uri: 'https://api.nimles.com/organization-management/public/graphql',
  fetch,
});

const categoryQuery = gql`
  query GetCategory($tenantId: Uuid!, $id: Uuid!) {
    category(tenantId: $tenantId, id: $id) {
      id
      name
      nameLocalized
      longName
      longNameLocalized
      summary
      summaryLocalized
      description
      descriptionLocalized
      uniqueName
      bannerId
      logoId
      tags
    }
  }
`;

const Category = ({
  category,
  organizations,
  productGroups,
  location,
}: Props) => {
  const { draft }: any = location
    ? queryString.parse(location.search)
    : { draft: false };

  const { data } = useQuery<{ category: CategoryModel }>(categoryQuery, {
    variables: { tenantId: process.env.GATSBY_TENANT, id: category?.id },
    skip: !draft,
  });

  if (data?.category) {
    category = data.category || category;
  }

  return (
    <CategoryPage
      category={category}
      productGroups={productGroups}
      organizations={organizations}
      location={location}
    />
  );
};

interface Props {
  category: CategoryModel;
  productGroups: ProductGroupWithProductProperties[];
  organizations: OrganizationModel[];
  location: Location;
}

const CategoryTemplate = ({ pageContext, location }) => {
  const { category, productGroups, organizations } = pageContext;

  const { longName, name, summary, bannerId, tags }: CategoryModel = category;

  const banner = getImageUrl(bannerId, 1920);

  const meta = {
    title: longName || name,
    description: summary,
    image: banner,
    keywords: tags?.join(', '),
  };

  return (
    <ApolloProvider client={client}>
      <Layout
        header
        footer
        headerAlwaysOpen={true}
        meta={meta}
        location={location}
      >
        <Category
          category={category}
          productGroups={productGroups}
          organizations={organizations}
          location={location}
        />
      </Layout>
    </ApolloProvider>
  );
};

export default CategoryTemplate;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
