import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { CategoryModel, OrganizationModel } from '@nimles/models';
import { Section, Container, Row, Column } from '@nimles/react-web-components';
import { ProductGroupWithProductProperties } from '../../../types';
import { CategoryBanner } from '../banner/CategoryBanner';
import { Text } from '../../Text';
import { OverlayLink } from '../../Overlay';
import { Map } from '../../map/Map';
import { getImageUrl } from '../../../utils';
import { MapMarker } from '../../map/MapMarker';
import { useSelector } from 'react-redux';
import { RootState, GeoState } from '../../../redux/types';
import marker from '../../../images/marker.svg';
import myposition from '../../../images/myposition.svg';
import { OrganizationGrid } from '../../organization/grid/OrganizationGrid';

const mapStyle = {
  width: '100%',
  height: '40vh',
};

export const ContainerTitle = styled.h3`
  position: relative;
  margin-top: 45px;
`;

const Logo = styled.img`
  margin-bottom: 30px;
  width: 120px;
`;

interface Props {
  category: CategoryModel;
  productGroups: ProductGroupWithProductProperties[];
  organizations: OrganizationModel[];
  location: Location;
}

export const CategoryPage = ({ category, organizations, location }: Props) => {
  const {
    name,
    description,
    logoId,
    uniqueName,
    longName,
  }: CategoryModel = category;
  const geo = useSelector<RootState, GeoState>(({ geo }) => geo);

  const locations = useMemo(
    () =>
      organizations
        .filter((organization) => organization.location)
        .map((organization) => ({
          ...organization.location,
          organization,
        })),
    [organizations]
  );

  return (
    <>
      <CategoryBanner category={category} location={location} />
      {description ? (
        <Section padding>
          <Container>
            <Row wrap="wrap">
              <Column xs={100} justify="center">
                <Text>
                  {logoId ? (
                    <Row justify="center">
                      <Column>
                        <Logo src={getImageUrl(logoId, 480)} alt={name} />
                      </Column>
                    </Row>
                  ) : null}
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </Text>
              </Column>
              <Column xs={100} md={50} lg={40} justify="center"></Column>
            </Row>
          </Container>
        </Section>
      ) : null}
      <Section padding>
        <Container>
          <Row wrap="wrap">
            <Column xs={100} justify="center">
              <Section>
                <OverlayLink to={'/karta/?category=' + uniqueName}>
                  Visa gårdsbutikerna på karta
                </OverlayLink>
                <Map
                  locations={locations}
                  mapStyle={mapStyle}
                  fitLocations={true}
                  disableDefaultUI
                >
                  {geo.userPosition ? (
                    <MapMarker icon={myposition} location={geo.userPosition} />
                  ) : null}
                  {locations.length > 0
                    ? locations.map((location, index) => (
                        <MapMarker
                          icon={marker}
                          key={index}
                          location={location}
                        />
                      ))
                    : null}
                </Map>
              </Section>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section padding>
        <Container>
          {!longName ? (
            <ContainerTitle>Alla gårdsbutiker med {name}</ContainerTitle>
          ) : (
            <ContainerTitle>{longName}</ContainerTitle>
          )}
          <OrganizationGrid
            organizations={organizations}
            orderBy={geo.userPosition ? 'distance' : 'name'}
          />
        </Container>
      </Section>
    </>
  );
};
